import * as singleSpa from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";

const postToLog = (msg) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      level: "info",
      message: msg,
    })
  };
  
  const url = new URL(window.location.href);
  const path = url.pathname.split('/').slice(0, 5).join('/');
  const postLogUrl = `/api-mx${path}/post-log`;

  if (!url.toString().includes("rootapp")) {
    fetch(postLogUrl, requestOptions);
  }
};


singleSpa.addErrorHandler && singleSpa.addErrorHandler((error) => {
  try {
    const status = singleSpa.getAppStatus(error.appOrParcelName);
    console.error("root lifecycle failure", { mx: error.appOrParcelName, status });
    postToLog(`root lifecycle failure ${error.appOrParcelName} ${status}`);
  } catch(e) {
    console.error("error get singlespa error", e);
  }
});

try {
  const routes = constructRoutes(document.querySelector("#layout"));
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      const imported = System.import(name);
      imported.then(() => {}, (err) => { 
        try {
          postToLog(`sysjs error for ${name}: ${err?.message}`);
        } catch (e) {}
      });
      return imported;
    },
  });

  const layoutEngine = constructLayoutEngine({ routes, applications });
  applications.every((app) => {
    try {
      singleSpa.registerApplication(app);
      return true;
    } catch (err) {
      console.error("app register error", err);
      return false;
    }
  });
  layoutEngine.activate();
  singleSpa.start({
    urlRerouteOnly: true,
  });
} catch (e) {
  postToLog(`root construct error: ${e?.message}`);
}
